@import "styles/variables";

$hover-background: rgba(
  $color: $white,
  $alpha: 0.15,
);

.header {
  color: $white;

  &__container {
    height: 100%;
    // needed for ie11
    margin: 0px;
    align-content: center;
  }

  &__home-link {
    height: 100%;
    display: flex;
    align-content: flex-start;
    min-width: 195px;
    padding: unset;
    padding-bottom: 8px;

    @media screen and (max-width: $md) {
      min-width: auto;
    }
  }

  &__logo {
    height: 100%;
    width: auto;
    padding: 0 0.5em;

    &--horizontal {
      margin-right: 40px;
      padding: 0;

      @media screen and (max-width: $md) {
        width: 120px;
        margin-right: 0;
      }
    }
  }

  &__project-selector {
    margin-left: 1.5em;
  }

  &__project-selector-button {
    color: $white;
    border-color: $white;

    &:hover {
      background-color: $hover-background;
    }
  }

  &__icon-button {
    color: inherit;
    padding: 6px;
    border-radius: 5px;

    &:hover {
      background-color: $hover-background;
    }
  }
}
