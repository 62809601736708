@import "styles/variables";

.main-layout {
  height: 100vh;
  margin-left: 0;
  overflow-y: auto;
  padding-top: 44px;
  display: flex;

  @media screen and (min-width: $md) {
    padding-left: 52px;
  }

  &__alert {
    min-width: 100%;
  }

  &__collapse {
    display: flex;
    min-width: 100%;
  }

  &__collapse-button {
    border: none;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    opacity: 0.8;
    height: 44px;
    background-color: rgba($sidebar, 0.9);

    &:hover {
      background-color: rgba($black, 0.1);
    }

    &:active,
    &:focus {
      border: none;
      outline: none;
    }

    > * {
      &:first-child {
        margin-right: 1.5em;
      }
    }

    &--collapsed {
      justify-content: center;

      > * {
        &:first-child {
          margin-right: 0;
        }
      }
    }
  }

  &__sidebar-footer {
    width: 100%;
    margin-top: auto;
  }

  &__with-sidebar {
    position: relative;
  }

  &__body {
    position: relative;
    z-index: 1;
    flex-grow: 1;
    width: 100%;
    padding: 1em 1.5em;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
  }

  .advanced-select__select__menu {
    z-index: 2;
  }

  .Toastify__toast {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: $primary;
    min-height: unset;
    font-family: inherit;
    cursor: default;

    .Toastify__progress-bar--default {
      background: $secondary;
    }
  }
  .MuiCollapse-container {
    min-width: 100%;
  }
}
