/**
COLORS
**/
$white: #ffffff;
$black: #000000;
$primary: #263548;
$primary-light: #232f3e;
$secondary: #f2682a;
$light-grey: #727b91;
$light-purple: #b1acc4;
$sidebar: #f9f9f9;
$background: #ffffff;
$error: #d62728;
$success: #69b764;
$blue: #2196f3;
$initialised: #729ece;
$pending: #ff7f0e;
$link: #0273ff;
$hover: #dedede;
$update: #e9a208;

/**
FONTS
**/
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 600;
$fs: 14px;

/**
BREAKPOINTS
**/
$xs: 0px;
$sm: 500px;
$md: 960px;
$lg: 1280px;
$xl: 1440px;
