@import "styles/variables";

.request {
  &__section {
    margin: 0 auto;
    flex: 4;
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > .MuiPaper-root {
      overflow: unset;
    }
  }

  &__output {
    width: 100%;
    max-width: 500px;
    margin: 20px auto 0px;
  }
}
