@import "variables";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: $background;

  #jsd-widget {
    visibility: hidden;
  }
}
