@import "styles/variables";

.home {
  &__section {
    max-width: $sm;
    margin: 0 auto;
  }

  &__welcome {
    margin-bottom: 20px;
  }
}
