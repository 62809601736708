@import "styles/variables";

.icon {
  color: $secondary;

  &--completed,
  &--available,
  &--available_building_failed,
  &--success {
    color: $success;
  }

  &--initialised {
    color: $initialised;
  }

  &--failed,
  &--failed_verification,
  &--deploying_failed,
  &--building_failed {
    color: $error;
  }

  &--pending,
  &--pending_verification,
  &--deploying,
  &--building,
  &--available_building {
    color: $pending;
  }

  &--processing,
  &--confirmation,
  &--running,
  &--uploading {
    color: $blue;
  }

  &--none {
    color: $light-grey;
  }

  &--disabled {
    color: rgba($light-grey, 0.4);
  }

  &--revert_icon_color {
    color: $white;
  }
}
