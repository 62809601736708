@import "styles/variables";

.page-container {
  max-width: $sm;
  width: 100%;
  flex-grow: 1;

  &--full-height {
    .page-container__content {
      height: 100%;
    }
  }

  &__content {
    margin-bottom: 1.5em;
  }
}
