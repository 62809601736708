@import "styles/variables";

.page-title {
  &__content {
    min-height: 2.5em;
    margin-bottom: -1em;

    &--title {
      margin-right: 1em;
    }
  }

  &__item {
    &--end {
      margin-left: auto;
    }

    > * {
      text-transform: uppercase;
    }

    .MuiButton-startIcon {
      margin-right: 5px;
    }
  }
}
